import { secureRoute } from '@uag/react-core';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Frame } from './Frame';
import { NotFound } from './NotFound';
import { Request, Requests } from './requests';
import { Teams } from './teams/Teams';
import { Unauthorized } from './Unauthorized';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route Component={secureRoute(Frame)} path="/">
                <Route element={<Navigate to={'/requests'} />} path="/" />
                <Route Component={secureRoute(Requests)} path="requests">
                    <Route Component={secureRoute(Request)} path=":requestId" />
                </Route>
                <Route Component={secureRoute(Teams)} path="teams" />
            </Route>
            <Route Component={Unauthorized} path="/unauthorized" />
            <Route Component={NotFound} path="*" />
        </Routes>
    );
};
