import { Button, Modal } from '@mantine/core';
import { welcomeApiClient } from '@shared/api/customMutator';
import { GetCompanyExistsResponse } from '@shared/api/models';
import { getCompanyExistsQueryKey } from '@shared/api/queries/companies/companies';
import { CustomerSelection, CustomerType } from '@shared/components/customer/customerSelection';
import { variants } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create, InstanceProps } from 'react-modal-promise';

import { InviteExistingCompany } from './InviteExistingCompany';
import { InviteNewCompany } from './InviteNewCompany';

type Props = InstanceProps<{ requestId?: string }>;

type State = 'CustomerSelection' | 'NewCustomer' | 'ExistingCustomer';

const InviteCompanyModal = ({ isOpen, onResolve, onReject }: Props) => {
    const { t } = useTranslation();
    const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.Existing);
    const [customerNumber, setCustomerNumber] = useState<string>('');

    const submit = (requestId?: string) => onResolve({ requestId });
    const reject = () => onReject();

    const [state, setState] = useState<State>('CustomerSelection');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const handleCustomerTypeChanged = (type: CustomerType) => setCustomerType(type);

    const handleContinue = () => {
        setError(undefined);
        if (customerType === CustomerType.Existing) {
            setIsLoading(true);
            welcomeApiClient
                .get<GetCompanyExistsResponse>(getCompanyExistsQueryKey(customerNumber).join())
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.data?.exists) {
                        setState('ExistingCustomer');
                    } else {
                        setError(t('customerNotFound'));
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setState('NewCustomer');
        }
    };

    const handleBack = () => setState('CustomerSelection');

    return (
        <Modal
            opened={isOpen}
            size={state === 'NewCustomer' ? '60rem' : '50rem'}
            title={t('inviteCompany')}
            onAbort={reject}
            onClose={reject}
        >
            {state === 'ExistingCustomer' && (
                <InviteExistingCompany customerNumber={customerNumber} onBack={handleBack} onClose={submit} />
            )}
            {state === 'NewCustomer' && <InviteNewCompany onBack={handleBack} onClose={submit} />}
            {state === 'CustomerSelection' && (
                <>
                    <div className="flex flex-col gap-6">
                        <CustomerSelection
                            customerNumber={customerNumber}
                            customerNumberError={error}
                            customerType={customerType}
                            setCustomerNumber={setCustomerNumber}
                            onCustomerTypeChanged={handleCustomerTypeChanged}
                        />
                    </div>
                    <div className="modal-action-section mt-2xl">
                        <Button className="modal-action" variant={variants.button.secondary} onClick={reject}>
                            {t('cancel')}
                        </Button>
                        <Button
                            className="modal-action"
                            disabled={customerType === CustomerType.Existing && !customerNumber}
                            loading={isLoading}
                            onClick={handleContinue}
                        >
                            {t('continue')}
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export const openInviteCompanyModal = create(InviteCompanyModal);
