import { TextInput } from '@mantine/core';
import { SelectableCard } from '@uag/react-core';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import { Card } from 'components/Card';
import existingCustomer from './assets/existingCustomer.svg';
import newCustomer from './assets/newCustomer.svg';
export const CustomerType = {
    New: 'New',
    Existing: 'Existing'
};

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];

type Props = {
    onCustomerTypeChanged: (value: CustomerType) => void;
    customerType: CustomerType;
    customerNumber?: string;
    setCustomerNumber: (value: string) => void;
    customerNumberError?: string;
};
export const CustomerSelection = ({
    customerType,
    onCustomerTypeChanged: handleCustomerTypeChanged,
    customerNumber,
    setCustomerNumber,
    customerNumberError
}: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <SelectableCard.Group
                className="w-full gap-6"
                orientation="horizontal"
                value={customerType}
                wrap="nowrap"
                onChange={handleCustomerTypeChanged}
            >
                <Card image={existingCustomer} imageClassName="h-40" text={t('existingCustomer')} value={CustomerType.Existing} />
                <Card
                    className={clsx({ ['card-selected']: customerType === CustomerType.New })}
                    image={newCustomer}
                    imageClassName="h-40"
                    text={t('newCustomer')}
                    value={CustomerType.New}
                />
            </SelectableCard.Group>
            {customerType === CustomerType.Existing && (
                <TextInput
                    description={t('customerNumberDescription')}
                    error={customerNumberError}
                    label={t('customerNumber')}
                    placeholder={t('customerNumberPlaceholder')}
                    value={customerNumber}
                    required
                    onChange={(event) => setCustomerNumber(event.currentTarget.value)}
                />
            )}
        </>
    );
};
