import { useGetCurrentContributor } from '@shared/api/queries/contributors/contributors';
import { useGetRequestDetail } from '@shared/api/queries/requests/requests';
import { CompanyInformation } from '@shared/components/company/ViewCompany/CompanyInformation';
import { RequestAttachments } from '@shared/components/company/ViewCompany/RequestAttachments';
import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActivateCustomerAction } from './actions/ActivateCustomerAction';
import { AdditionalInputRequiredAction } from './actions/AdditionalDataInput';
import { ApproveRequestAction } from './actions/ApproveRequestAction';
import { DeclineRequestAction } from './actions/DeclineRequestAction';
import { EditModeAction } from './actions/EditModeAction';
import { ResendInvitation } from './actions/ResendInvitation';
import { Remarks } from './Remarks';
import { RequestActions } from './RequestActions';
import { RequestHeader } from './RequestHeader';
import { RequestProvider } from './RequestProvider';

export const Request = () => {
    const { requestId = '' } = useParams();
    const { data, isLoading } = useGetRequestDetail(requestId);
    const { data: currentContributorApiResponse } = useGetCurrentContributor();

    const { t } = useTranslation();
    const request = data?.data;
    const currentContributor = currentContributorApiResponse?.data;

    if (isLoading) {
        return <LoadingAnimation />;
    }

    return (
        <div>
            {request && currentContributor && (
                <div className="flex flex-col gap-2xl">
                    <RequestProvider request={request}>
                        <RequestHeader>
                            <RequestActions>
                                <EditModeAction visibleInStates={'DataVerification'} />
                                <AdditionalInputRequiredAction visibleInStates={'DataVerification'} />
                                <DeclineRequestAction visibleInStates={'DataVerification'} />
                                <ApproveRequestAction visibleInStates={'DataVerification'} />
                                <ActivateCustomerAction visibleInStates={'SignatureVerification'} />
                                <ResendInvitation visibleInStates={'Invited'} />
                            </RequestActions>
                        </RequestHeader>

                        <div>
                            <div>{t('companyData')}</div>
                            <div className="text-sm border border-border-disabled bg-background-gray-ultra-light">
                                <CompanyInformation request={request} />
                            </div>
                        </div>
                        <Remarks />
                        <RequestAttachments request={request} />
                    </RequestProvider>
                </div>
            )}
        </div>
    );
};
